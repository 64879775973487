import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query ComplaintsQuery {
  file(relativePath: {eq: "nemo-website-figures-h2-2019.pdf"}) {
    publicURL
  }
}
`

const ComplaintsPage = ({ data }) => (
    <Layout>
        <Seo title={'Nemo Personal Finance Complaints Procedure'} description={'Nemo Personal Finance Complaints Procedure - if you have cause for complaint, find out about how to raise it and the procedure we go through to rectify the issue.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Nemo Personal Finance Complaints Procedure</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">At Nemo Personal Finance we aim to provide you with the highest
                                possible standard of service. However, we recognise that, on occasion, you may feel you
                                have cause for complaint. If you are unhappy with any aspect of the service you have
                                received, we would very much like to know.</p>
                        </div>
                    </div>
                    <h2>For details on the Nemo Complaints Procedure, please continue reading below. For complaints data
                        for the 6 month period ending 31 December 2019 please download the <a className="noOutline"
                                                                                              href={data.file.publicURL}>Nemo
                            Complaints Publication Report</a>.</h2>    <p>&nbsp;</p>
                    <p>The Nemo Complaints Procedure aims to provide you with a swift and satisfactory resolution to any
                        issues you have encountered or problems you may want to draw our attention to.</p>
                    <p>If you have a complaint, we promise to:</p>
                    <ul>
                        <li>Ensure that you are heard</li>
                        <li>Understand your complaint</li>
                        <li>Provide an explanation</li>
                        <li>Act as soon as possible to rectify the issue</li>
                    </ul>
                    <p>How does it work?</p>
                    <ol>
                        <li>Contact us on the telephone number or email address below. We’ll do all we can to deal with
                            your complaint immediately. If your complaint cannot be resolved within three working days
                            following date of receipt, we will write to you within five working days to acknowledge it
                            has been received and confirm our understanding.
                        </li>
                        <li>We will provide you with a response to the issues you have raised. We will endeavour to do
                            this as quickly as possible.
                        </li>
                        <li>If we are unable to fully respond to the issue within 28 days, we will contact you to
                            explain why this has not been possible, keep you informed of the complaint’s progress and
                            let you know when we will contact you again.
                        </li>
                        <li>After a further 28 days we will contact you again - either with a full response or reasons
                            why we have been unable to provide this. If we are unable to provide a full response, we
                            will inform you of any rights you have to refer the matter on to the Financial Ombudsman
                            Service.
                        </li>
                    </ol>
                    <p>In the vast majority of cases, we are able to resolve issues quickly and satisfactorily by
                        dealing with our customers directly. However, if you feel your complaint has not been fully, or
                        fairly, dealt with, you may have the option of contacting the Financial Ombudsman Service (FOS)
                        who may be able to take the matter further. You have six months from the date of our final
                        response letter to refer the matter to FOS. If we take longer than three working days following
                        the date of receipt to resolve your complaint, a leaflet detailing the FOS complaint procedure
                        will accompany our final response.</p>
                    <p>Nemo Personal Finance<br /> PO Box 89<br />Queen Street<br />Cardiff CF10 1UA</p>
                    <p>Tel:&nbsp;0800&nbsp;138 2935&nbsp;<br />E mail: <a href="mailto:complaints@nemoloans.co.uk"
                                                                        shape="rect">complaints@nemoloans.co.uk</a></p>
                    <p>You can contact the Financial Ombudsman Service on:</p>    <p>Telephone number:&nbsp;0300 123 9
                    123</p>    <p>Alternatively, you can write to:</p>    <p>The Financial Ombudsman Service<br />Exchange
                    Tower<br />London<br />E14 9SR</p>    <p><a
                    href="http://www.financial-ombudsman.org.uk/consumer/complaints.htm"
                    shape="rect">www.financial-ombudsman.org.uk/consumer/complaints.htm</a>&nbsp;&nbsp; <br /></p>    <p>
                    <strong>Payment Protection Insurance ("PPI") </strong></p>    <p>The deadline for PPI miss-selling
                    complaints has now passed, ending on the 29th August 2019.We can only consider PPI complaints
                    if:</p>
                    <ul>
                        <li>The complaint is regarding a ‘live’ PPI policy with us, or</li>
                        <li>There were exceptional reasons as to why the complaint was not received prior to the
                            deadline, which you will need to provide.
                        </li>
                    </ul>
                    <p>If you meet one of the above criteria and would like to contact us regarding a complaint please
                        email the Complaints Manager at <a href="mailto:complaints@principality.co.uk"
                                                           shape="rect">complaints@principality.co.uk</a>.</p>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default ComplaintsPage;